




import { Component, Vue } from 'vue-property-decorator';

// TODO: Implement this

@Component
export default class Statistics extends Vue {}
